var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c(
        "b-alert",
        {
          attrs: {
            variant: _vm.msgVariant || "",
            show: _vm.shown,
            dismissible: _vm.dismissable,
          },
        },
        [
          _vm.msgVariant === "success"
            ? _c("fa-icon", { attrs: { icon: "check-circle" } })
            : _vm.msgVariant === "warning"
            ? _c("fa-icon", { attrs: { icon: "exclamation-triangle" } })
            : _vm.msgVariant === "danger"
            ? _c("fa-icon", { attrs: { icon: "exclamation-circle" } })
            : _vm.msgVariant === "info"
            ? _c("fa-icon", { attrs: { icon: "info-circle" } })
            : _vm._e(),
          _vm._v(" " + _vm._s(_vm.formattedMessage) + " "),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }